import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";

import { datinglist } from "../dating-list";

import { Location } from '@angular/common';

@Component({
  selector: 'app-dating-items',
  templateUrl: './dating-items.component.html',
  styleUrls: ['./dating-items.component.scss']
})

export class DatingItemsComponent implements OnInit {
  datinglist = datinglist;
  path:string;

  constructor(private route: ActivatedRoute, private location: Location) { 

  }

  goBack() {
    this.location.back();
  } 

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.path = params.get("datingId");
    })
  }
}
