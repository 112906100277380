import { CategoriesComponent } from './categories/categories.component';

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms'; //jsut for form syntax like the inputs router. not really using now

import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { NavComponent } from './nav/nav.component';
import { HomeComponent } from './home/home.component';

import { MatcherComponent } from './matcher/matcher.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {MatTabsModule} from '@angular/material/tabs';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';



import { AppRoutingModule }     from './app-routing.module';
import { ArtistsComponent } from './artists/artists.component';
import { ArtistsItemsComponent } from './artists-items/artists-items.component';
import { ArtistsItemsDetailsComponent } from './artists-items-details/artists-items-details.component';
import { DatingComponent } from './dating/dating.component';
import { DatingItemsComponent } from './dating-items/dating-items.component';
import { DatingItemsDetailsComponent } from './dating-items-details/dating-items-details.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { VintageComponent } from './vintage/vintage.component';
import { VintageItemsComponent } from './vintage-items/vintage-items.component';
import { VintageItemsDetailsComponent } from './vintage-items-details/vintage-items-details.component';
import { StreetwearComponent } from './streetwear/streetwear.component';
import { StreetwearItemsComponent } from './streetwear/streetwear-items/streetwear-items.component';
import { StreetwearItemsDetailsComponent } from './streetwear/streetwear-items/streetwear-items-details/streetwear-items-details.component';

@NgModule({
  declarations: [
    AppComponent,
    TopBarComponent,
    NavComponent,
    HomeComponent,
    MatcherComponent,
    CategoriesComponent,
    ArtistsComponent,
    ArtistsItemsComponent,
    ArtistsItemsDetailsComponent,
    DatingComponent,
    DatingItemsComponent,
    DatingItemsDetailsComponent,
    VintageComponent,
    VintageItemsComponent,
    VintageItemsDetailsComponent,
    StreetwearComponent,
    StreetwearItemsComponent,
    StreetwearItemsDetailsComponent,

  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    
    RouterModule.forRoot([
      {path: '', component: HomeComponent},
      {path: 'categories', component: CategoriesComponent},
      {path: 'matcher', component: MatcherComponent},
      {path: 'artists', component: ArtistsComponent},
      {path: 'artists/:artistsId', component: ArtistsItemsComponent},
      {path: 'artists/:artistsId/:artistsType', component: ArtistsItemsDetailsComponent},
      {path: 'dating', component: DatingComponent},
      {path: 'dating/:datingId', component: DatingItemsComponent},
      {path: 'dating/:datingId/:datingType', component: DatingItemsDetailsComponent},
      {path: 'vintage', component: VintageComponent},
      {path: 'vintage/:vintageId', component: VintageItemsComponent},
      {path: 'vintage/:vintageId/:vintageType', component: VintageItemsDetailsComponent},
      {path: 'streetwear', component: StreetwearComponent},
      {path: 'streetwear/:streetwearId', component: StreetwearItemsComponent},
      {path: 'streetwear/:streetwearId/:streetwearType', component: StreetwearItemsDetailsComponent}
    ]),
    BrowserAnimationsModule,
    MatTabsModule,
    MatButtonModule,
    MatIconModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }