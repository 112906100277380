import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";

import { streetwearlist } from '../../../streetwear-list';

import { Location } from '@angular/common';

@Component({
  selector: 'app-streetwear-items-details',
  templateUrl: './streetwear-items-details.component.html',
  styleUrls: ['./streetwear-items-details.component.scss']
})

export class StreetwearItemsDetailsComponent implements OnInit {
  streetwearlist = streetwearlist;
  path:string;
  pathsub: string;

  product;

  constructor(
    private route: ActivatedRoute,
    private location: Location
  ) {
   }

   goBack() {
    this.location.back();
  }  

  ngOnInit(){
    this.route.paramMap.subscribe(params => {
      this.path = params.get("streetwearId");
      this.pathsub = params.get("streetwearType");

      this.product = streetwearlist[this.path][+params.get('streetwearType')];
    })
  }

}
