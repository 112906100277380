

<!-- <div *ngFor='let obj of artistslist[path]; index as artistsType'>
    {{obj.name}}
    <a [routerLink]="[artistsType]">
        dsjk
    
    </a>
</div> -->
<div class="items">
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center my-4">
         <button class="back" (click)="goBack()"><i class="fas fa-arrow-left"></i></button>
           <h1 class="mb-0">{{path}}</h1>
         </div>
        <!-- <h2>{{path}}</h2> -->
       <div>
          <div class="btn btn-primary">Filter</div>
       </div>
    </div>
    <div class="row heroes">
       <div class="col-xl-4 col-lg-6 col-md-6" *ngFor='let obj of artistslist[path]; index as artistsType'>
          <a class="card" [routerLink]="[artistsType]">
             <div class="card-thumbnail"><img src="assets/images/{{obj.torso}}.png" width="142px" height="142px" alt=""></div>
             <div class="card-thumbnail"><img src="assets/images/{{obj.pants}}.png" width="142px" height="142px" alt=""></div>
          </a>
       </div>
    </div>
 </div> 