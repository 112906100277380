<div class="d-flex align-items-center my-4">
    <!-- <button class="back" (click)="goBack()"><i class="fas fa-arrow-left"></i></button> -->
    <h1 class="mb-0">{{path}}</h1>
</div>
<div class="row items">
    <div class="col-xl-4 col-lg-6 col-6" *ngFor='let obj of streetwearlist[path]; index as streetwearType'>
        <a class="card" [routerLink]="[streetwearType]">
           <!-- <div class="items-point">18</div> -->
           <div class="card-thumbnail"><img src="assets/images/torso/{{obj.torso}}.png" width="142px" height="142px" alt=""></div>
           <div class="card-thumbnail"><img src="assets/images/pants/{{obj.pants}}.png" width="142px" height="142px" alt=""></div>
        </a>
     </div>
</div>