import { Match } from './matcher';

export const HATER: Match[] = [
    {
        id: 12,
        name: 'blue hat',
        url: 'TeddyFresh.com/shirt'
    },
    {
        id: 122,
        name: 'green hat',
        url: 'TeddyFresh.com/longsleeve'
    },
];