const datinglist = {
    "First Date": [
        {
            id: 0,
            name: "Casual Punk",
            icon: 'fas fa-mobile-alt',
            torso: 'Regular Fit Crew-neck T-shirt Beige',
            torsoprice: '$6.99',
            torsourl: 'https://www2.hm.com/en_us/productpage.0685816011.html',
            pants:'Skinny Jeans Black H & M',
            pantsprice: '$24.99',
            pantsurl: 'https://www2.hm.com/en_us/productpage.0720504001.html',
            outfitinfo: 'A simplistic look that gives off a punk vibe.', 
            outfittype: 'This is a casual wear, plain tee, skinny jeans, providing a simplistic look. They say skinny jeans are out of style, but looking well is timeless.',
            shoes: 'air force one star pack',
            shoesprice: '$328',
            shoesurl: 'https://www.goat.com/sneakers/air-force-1-low-all-star-swoosh-pack-2018-ah8462-102',
        },
        {
            id: 0,
            name: "Chill Look",
            icon: 'fas fa-mobile-alt',
            torso: 'essentials hoodie black', 
            torsoprice: '$500',
            torsourl: 'https://stockx.com/fear-of-god-essentials-tmc-crenshaw-hoodie-black?utm_source=af&utm_medium=imp&utm_campaign=123201&impactSiteId=2Yzx1jwoqxyLUMxwUx0Mo3bwUkEUQcVVR0t0UA0&clickid=2Yzx1jwoqxyLUMxwUx0Mo3bwUkEUQcVVR0t0UA0&utm_term=2Yzx1jwoqxyLUMxwUx0Mo3bwUkEUQcVVR0t0UA0&utm_content=104620_530344&irgwc=1', 
            pants:'acid wash fleece sweatpant', 
            pantsprice: '$225',
            pantsurl: 'https://www.alexanderwang.com/ca-en/acid-wash-dense-fleece-sweatpant+192722066720.html?utm_campaign=affiliazione_us&utm_content=10&utm_medium=affiliate&utm_source=FlexOffers.com&siteID=PPkX79_c.b0-t_kTIj2lkpCwPa8X25ofag&ranMID=36828&ranEAID=PPkX79%2Fc*b0&ranSiteID=PPkX79_c.b0-t_kTIj2lkpCwPa8X25ofag',
            outfitinfo: 'If you are going on a date in a cold spot, wear a nice fitted hoodie, with cut clean joggers.', 
            outfittype: 'A less than casual look, used mainly in cold weather.',
            shoes: 'air force one star pack',
            shoesprice: '$328',
            shoesurl: 'https://www.goat.com/sneakers/air-force-1-low-all-star-swoosh-pack-2018-ah8462-102',
        },
        {
            id: 0,
            name: "Flannel Lumber",
            icon: 'fas fa-mobile-alt',
            torso: 'Cotton Flannel Shirt',
            torsoprice: '$24.99',
            torsourl: 'https://www2.hm.com/en_us/productpage.0908891001.html',
            pants:'Skinny Jeans Dark Shade H & M',
            pantsprice: '$24.99',
            pantsurl: 'https://www2.hm.com/en_us/productpage.0720504008.html',
            outfitinfo: 'This outfit can be used for dinner dates, walk in the park, get togethers.', 
            outfittype: 'This outfit is a casual and simple look.',
            shoes: 'air force one star pack',
            shoesprice: '$328',
            shoesurl: 'https://www.goat.com/sneakers/air-force-1-low-all-star-swoosh-pack-2018-ah8462-102',
        },
        {
            id: 0,
            name: "Black and White",
            icon: 'fas fa-mobile-alt',
            torso: 'Relaxed Fit Sweatshirt H&M',
            torsoprice: '$14.99',
            torsourl: 'https://www2.hm.com/en_us/productpage.0685813041.html',
            pants:'Regular Tapered Cropped Jeans H&M',
            pantsprice: '$19.99',
            pantsurl: 'https://www2.hm.com/en_us/productpage.0927964003.html',
            outfitinfo: 'Mid tone black jeans, with a fresh long sleeve. Topped with Air Force Ones.', 
            outfittype: 'This is a casual yet cozy look.',
            shoes: 'air force one star pack',
            shoesprice: '$328',
            shoesurl: 'https://www.goat.com/sneakers/air-force-1-low-all-star-swoosh-pack-2018-ah8462-102',
        },
        {
            id: 0,
            name: "Vanilla Cream",
            icon: 'fas fa-mobile-alt',
            torso: 'Long Fit T-shirt Beige',
            torsoprice: '$9.99',
            torsourl: 'https://www2.hm.com/en_us/productpage.0598755051.html',
            pants:'Regular Tapered Cropped Jeans H&M',
            pantsprice: '$19.99',
            pantsurl: 'https://www2.hm.com/en_us/productpage.0927964003.html',
            outfitinfo: 'This outift is simple but effective. The simplicity is a great start for a first date.', 
            outfittype: 'Casual yet formal for dinners, activities, and nightlife.',
            shoes: 'air force one star pack',
            shoesprice: '$328',
            shoesurl: 'https://www.goat.com/sneakers/air-force-1-low-all-star-swoosh-pack-2018-ah8462-102',
        },
        {
            id: 0,
            name: "Fresh n Chill",
            icon: 'fas fa-mobile-alt',
            torso: 'Regular Fit V-neck T-shirt',
            torsoprice: '$5.99',
            torsourl: 'https://www2.hm.com/en_us/productpage.0763275001.html',
            pants:'Minimalist sweatpant',
            pantsprice: '$14.99',
            pantsurl: 'https://www.simons.com/en/men-clothing/pants/joggers/minimalist-sweatpant--1533-9101?catId=6755&colourId=11',
            outfitinfo: 'minimal look, fancy and goes well in any approach', 
            outfittype: 'This is a cozy streetwear look, can be used to any occasion',
            shoes: 'air force one star pack',
            shoesurl: 'https://www.flightclub.com/air-jordan-1-retro-high-og-black-royal-white-800564?utm_source=google_int&utm_medium=google_shopping_int&utm_campaign=1072630453_50626141377&utm_content=253990353042_&utm_term=887223097921&gclid=CjwKCAiAo5qABhBdEiwAOtGmbgHNR9XAXeqPfa7MKhcMAZ0ywD1RQyMgh4cIIygudr5P2soDrkw4cBoCzB0QAvD_BwE'
        },
    ],

    "Dinner Date": [
        {
            id: 0,
            name: "More than Casual",
            icon: 'fas fa-mobile-alt',
            torso: 'Fine-knit Turtleneck Sweater',
            torsoprice: '$24.99',
            torsourl: 'https://www2.hm.com/en_us/productpage.0715828037.html',
            pants:'Skinny Fit Suit Pants',
            pantsprice: '$34.99',
            pantsurl: 'https://www2.hm.com/en_us/productpage.0714032002.html',
            outfitinfo: 'Good for dinner dates, in locations where people are dressed more casual.', 
            outfittype: 'This is a formal wear, specifically can be used in formal and casual areas.',
            shoes: 'Desert Boots',
            shoesprice: '$59.99',
            shoesurl: 'https://www2.hm.com/en_us/productpage.0907460001.html'
        },
        {
            id: 0,
            name: "Punk Dress Up",
            icon: 'fas fa-mobile-alt',
            torso: 'Regular Fit Oxford Shirt',
            torsoprice: '$24.99',
            torsourl: 'https://www2.hm.com/en_us/productpage.0795836001.html',
            pants:'Skinny Fit Suit Pants H&M',
            pantsprice: '$34.99',
            pantsurl: 'https://www2.hm.com/en_us/productpage.0843465013.html',
            outfitinfo: 'This outfit can be used in elegant party scenes, nice dinner dates, and casual hang outs.', 
            outfittype: 'Casual and formal. Punk proper feel to it.',
            shoes: 'Desert Boots',
            shoesprice: '$59.99',
            shoesurl: 'https://www2.hm.com/en_us/productpage.0907460001.html'
        },
        {
            id: 0,
            name: "Suit Up",
            icon: 'fas fa-mobile-alt',
            torso: 'Slim Fit Blazer',
            torsoprice: '$69.99',
            torsourl: 'https://www2.hm.com/en_us/productpage.0713986001.html',
            pants:'Skinny Fit Suit Pants',
            pantsprice: '$34.99',
            pantsurl: 'https://www2.hm.com/en_us/productpage.0714032002.html',
            outfitinfo: 'Worn for fancy events, anyone wearing suits, fancy dinners, or even the ball', 
            outfittype: 'This is a formal wear only.',
            shoes: 'Derby Shoes',
            shoesprice: '$39.99',
            shoesurl: 'https://www2.hm.com/en_us/productpage.0934698001.html',
        },
        {
            id: 0,
            name: "Slightly Formal",
            icon: 'fas fa-mobile-alt',
            torso: 'Slim Fit Shirt Buttons',
            torsoprice: '$14.99',
            torsourl: 'https://www2.hm.com/en_us/productpage.0557908028.html',
            pants:'Skinny Fit Suit Pants',
            pantsprice: '$34.99',
            pantsurl: 'https://www2.hm.com/en_us/productpage.0714032002.html',
            outfitinfo: 'Slightly Formal outfit can be worn in formal events and in casual events.', 
            outfittype: 'Formal and casual wear',
            shoes: 'Derby Shoes',
            shoesprice: '$39.99',
            shoesurl: 'https://www2.hm.com/en_us/productpage.0934698001.html',
        },
    ],

    "Cold Approach": [
        {
            id: 0,
            name: "Casual Streetwear",
            icon: 'fas fa-mobile-alt',
            torso: 'Slim Fit Shirt Buttons',
            torsoprice: '$14.99',
            torsourl: 'https://www2.hm.com/en_us/productpage.0557908028.html',
            pants:'H and M Ripped Blue jeans', 
            pantsprice: '$39.99',
            pantsurl: 'https://www2.hm.com/en_us/productpage.0427159006.html',
            outfitinfo: 'Walk up and look impressive. Not too much and not too little.', 
            outfittype: 'This is a street wear and casual look.',
            shoes: 'air force one star pack',
            shoesprice: '$328',
            shoesurl: 'https://www.goat.com/sneakers/air-force-1-low-all-star-swoosh-pack-2018-ah8462-102',
        },
        {
            id: 0,
            name: "Falling Spring",
            icon: 'fas fa-mobile-alt',
            torso: 'Authentic jean jacket',
            torsoprice: '$79.00',
            torsourl: 'https://www.simons.com/en/men-clothing/coats-outerwear/denim-jackets/authentic-jean-jacket--8627-1672335',
            pants:'Regular Tapered Cropped Jeans H&M',
            pantsprice: '$19.99',
            pantsurl: 'https://www2.hm.com/en_us/productpage.0927964003.html',
            outfitinfo: 'Can be used in Fall and spring. Long sleeve denim, black jeans and royal blues.', 
            outfittype: 'Streetwear look.',
            shoes: 'Jordan 1 Royal',
            shoesprice: '+$450',
            shoesurl: 'https://www.flightclub.com/air-jordan-1-retro-high-og-black-royal-white-800564?utm_source=google_int&utm_medium=google_shopping_int&utm_campaign=1072630453_50626141377&utm_content=253990353042_&utm_term=887223097921&gclid=CjwKCAiAo5qABhBdEiwAOtGmbgHNR9XAXeqPfa7MKhcMAZ0ywD1RQyMgh4cIIygudr5P2soDrkw4cBoCzB0QAvD_BwE'
        },
        {
            id: 0,
            name: "Summer Chill",
            icon: 'fas fa-mobile-alt',
            torso: 'Regular Fit V-neck T-shirt',
            torsoprice: '$5.99',
            torsourl: 'https://www2.hm.com/en_us/productpage.0763275001.html',
            pants:'Minimalist sweatpant',
            pantsprice: '$14.99',
            pantsurl: 'https://www.simons.com/en/men-clothing/pants/joggers/minimalist-sweatpant--1533-9101?catId=6755&colourId=11',
            outfitinfo: 'minimal look, fancy and goes well in any approach.', 
            outfittype: 'This is a cozy streetwear look, can be used to any occasion',
            shoes: 'air force one star pack',
            shoesprice: '$328',
            shoesurl: 'https://www.goat.com/sneakers/air-force-1-low-all-star-swoosh-pack-2018-ah8462-102',
        },
        {
            id: 0,
            name: "Olive Chill",
            icon: 'fas fa-mobile-alt',
            torso: 'Regular Fit V-neck T-shirt',
            torsoprice: '$5.99',
            torsourl: 'https://www2.hm.com/en_us/productpage.0763275001.html',
            pants:'Skinny Jogger Olive Pants',
            pantsprice: '$39',
            pantsurl: 'https://www.hollisterco.com/shop/us/p/skinny-twill-jogger-pants-24347463?categoryId=166248&seq=12&faceout=model1',
            outfitinfo: 'Minimal look, white tee and white shoes makes Olive standout.', 
            outfittype: 'This is a cozy streetwear look, can be used to any occasion.',
            shoes: 'air force one star pack',
            shoesprice: '$328',
            shoesurl: 'https://www.goat.com/sneakers/air-force-1-low-all-star-swoosh-pack-2018-ah8462-102',
        },
    ],
    "Dating App": [
        {
            id: 0,
            name: "Olive Chill",
            icon: 'fas fa-mobile-alt',
            torso: 'Regular Fit V-neck T-shirt',
            torsoprice: '$5.99',
            torsourl: 'https://www2.hm.com/en_us/productpage.0763275001.html',
            pants:'Skinny Jogger Olive Pants',
            pantsprice: '$39',
            pantsurl: 'https://www.hollisterco.com/shop/us/p/skinny-twill-jogger-pants-24347463?categoryId=166248&seq=12&faceout=model1',
            outfitinfo: 'Minimal look, white tee and white shoes makes Olive standout.', 
            outfittype: 'This is a cozy streetwear look, can be used to any occasion.',
            shoes: 'air force one star pack',
            shoesprice: '$328',
            shoesurl: 'https://www.goat.com/sneakers/air-force-1-low-all-star-swoosh-pack-2018-ah8462-102',
        },
        {
            id: 0,
            name: "Slightly Formal",
            icon: 'fas fa-mobile-alt',
            torso: 'Slim Fit Shirt Buttons',
            torsoprice: '$14.99',
            torsourl: 'https://www2.hm.com/en_us/productpage.0557908028.html',
            pants:'Skinny Fit Suit Pants',
            pantsprice: '$34.99',
            pantsurl: 'https://www2.hm.com/en_us/productpage.0714032002.html',
            outfitinfo: 'Slightly Formal outfit can be worn in formal events and in casual events.', 
            outfittype: 'Formal and casual wear',
            shoes: 'Derby Shoes',
            shoesprice: '$39.99',
            shoesurl: 'https://www2.hm.com/en_us/productpage.0934698001.html',
        },
        {
            id: 0,
            name: "Punk Dress Up",
            icon: 'fas fa-mobile-alt',
            torso: 'Regular Fit Oxford Shirt',
            torsoprice: '$24.99',
            torsourl: 'https://www2.hm.com/en_us/productpage.0795836001.html',
            pants:'Skinny Fit Suit Pants H&M',
            pantsprice: '$34.99',
            pantsurl: 'https://www2.hm.com/en_us/productpage.0843465013.html',
            outfitinfo: 'This outfit can be used in elegant party scenes, nice dinner dates, and casual hang outs.', 
            outfittype: 'Casual and formal. Punk proper feel to it.',
            shoes: 'Desert Boots',
            shoesprice: '$59.99',
            shoesurl: 'https://www2.hm.com/en_us/productpage.0907460001.html'
        },
        {
            id: 0,
            name: "Suit Up",
            icon: 'fas fa-mobile-alt',
            torso: 'Slim Fit Blazer',
            torsoprice: '$69.99',
            torsourl: 'https://www2.hm.com/en_us/productpage.0713986001.html',
            pants:'Skinny Fit Suit Pants',
            pantsprice: '$34.99',
            pantsurl: 'https://www2.hm.com/en_us/productpage.0714032002.html',
            outfitinfo: 'Worn for fancy events, anyone wearing suits, fancy dinners, or even the ball', 
            outfittype: 'This is a formal wear only.',
            shoes: 'Derby Shoes',
            shoesprice: '$39.99',
            shoesurl: 'https://www2.hm.com/en_us/productpage.0934698001.html',
        },

    ],
  };
  
  export { datinglist };
  