import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";

import {artistslist } from '../artists-list';

import { Location } from '@angular/common';

@Component({
  selector: 'app-artists-items-details',
  templateUrl: './artists-items-details.component.html',
  styleUrls: ['./artists-items-details.component.scss']
})
export class ArtistsItemsDetailsComponent implements OnInit {
  artistslist = artistslist;
  path: string;
  pathsub: string;

  product;

  constructor(
    private route: ActivatedRoute,
    private location: Location
  ) {
    
   }

   goBack() {
    this.location.back();
  }  

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.path = params.get("artistsId");
      this.pathsub = params.get("artistsType");

      this.product = artistslist[this.path][+params.get('artistsType')];
    });
  }

}
