<div class="header">




  <button (click)="toggleClass()" [class.active]="classApplied">
    <div class="menu">
      <div class="menu-line"></div>
    </div>
  </button>
    <a [routerLink]="['/']" routerLinkActive="active"  class="nav-brand text-center">
      Fitsnip
    </a>
</div>
<nav [class.active]="classApplied">
  <a [routerLink]="['/']" routerLinkActive="active"  class="nav-brand text-center">
  Fitsnipp</a>
  <ul>
    <li><a [routerLink]="['/']" (click)="toggleClass()"><i class="far fa-user-circle"></i> Home</a></li>
    <!-- <li><a [routerLink]="['matcher']" (click)="toggleClass()"><i class="fas fa-camera-retro"></i> Builder</a></li> -->
    <!-- <li><a href="#" (click)="toggleClass()"><i class="fas fa-camera-retro"></i> Accessories</a></li>
    <li><a href="#" (click)="toggleClass()"><i class="fas fa-camera-retro"></i> Shoes</a></li> -->
  </ul>
</nav>