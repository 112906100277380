const artistslist = {
    badbunny: [
      {
        id: 0,
        name: "La Difficil Music Video",
        glasses: 'Gentle Monster',
        glassesprice: '$230',
        glassesurl: "https://www.farfetch.com/shopping/men/gentle-monster-chapssal-or1-sunglasses-item-13943709.aspx",
        torso: 'SWEATSHIRT ZIPPÉ À CAPUCHE TENNIS',
        torsoprice: '$70',
        torsourl: 'https://www.lacoste.com/fr/lacoste/homme/vetements/sweatshirts/sweatshirt-zippe-a-capuche-tennis-lacoste-sport-en-molleton-uni/3614036048133.html',
        pants:'PANTALON DE SURVETEMENT',
        pantsprice: '30$',
        pantsurl: 'https://global.lacoste.com/fr/lacoste/homme/vetements/pantalons-shorts/chinos/pantalon-de-survetement-tennis-lacoste-sport-coupe-regular/XH9507-00.html?color=EUA',
        outfitinfo: 'this outfit was from a music video called La Difficil by bad bunny. Influenced by the 90s jump suit music videos', 
        outfittype: 'This is a cozy streetwear look, can be used to any occasion'
    },
      {
        id: 12, 
        name: 'Drake - Mia', 
        glasses: 'channel paris sunglasses', 
        glassesprice: '$4,500',
        glassesurl: 'https://www.1stdibs.com/jewelry/objets-dart-vertu/frames/chanel-sunglasses-cc-logos-eye-wear-black-chanel-sunglasses/id-j_8800002', 
        torso: 'latinoheat', 
        torsoprice: '$23',
        torsourl: 'https://amazon.com/s?k=Youth+Eddie+Guerrero+Classic+Latino+Heat+HQ+Wrestling+Tee+Shirt&tag=spotern-20&linkCode=ogi&th=1&psc=1', 
        pants:'Waist Slant Pocket Striped Pants', 
        pantsprice: '12$',
        pantsurl: 'https://us.romwe.com/Guys-Drawstring-Waist-Slant-Pocket-Striped-Pants-p-484190-cat-857.html?url_from=usplasmpants07190719903XL_ssc&gclid=EAIaIQobChMI9tGw9Yrx7QIVRlXVCh3soQ76EAQYAyABEgIOV_D_BwE',
        outfitinfo: 'this outfit was based on the MIA music video featuring Drake.',
        outfittype: 'This is a more upper streetwear look. Skating occasion, to casual hang out.'
      },
      {
        id: 12, 
        name: 'Bad Bunny Cartoon Network', 
        torso: 'long sleeve cartoon network', 
        torsoprice: '$10',
        torsourl: 'https://poshmark.com/listing/Old-School-Cartoon-Network-Longsleeve-5f5d8148ff7c5a58ff59994b?l_con=PREOWNED%2FUSED&utm_source=gdm_unpaid', 
        pants:'joggers stripes', 
        pantsprice: '70$',
        pantsurl: 'https://www.depop.com/products/caldon_96-palace-basically-a-jogger-black/',
        shoes: 'air force one star pack',
        shoesprice: '$328',
        shoesurl: 'https://www.goat.com/sneakers/air-force-1-low-all-star-swoosh-pack-2018-ah8462-102',
        outfitinfo: 'this outfit was from an instagram post by Bad Bunny',
        outfittype: 'This outfit is a streetwear, fall/spring look.'
      }
    ],
    drake: [
        {
            id: 12, 
            name: 'Drake Denim Look', 
            torso: 'Chrome Hearts denim jacket', 
            torsoprice: '$68',
            torsourl: 'https://www.zappos.com/p/levis-mens-type-iii-sherpa-trucker-jacket-mustard/product/8978740/color/1904?PID=9237743&AID=14044464&utm_source=Skimlinks&splash=none&utm_medium=affiliate&cjevent=cde8aea677b711eb833c00000a24060e&utm_campaign=5370367&utm_term=9237743&utm_content=14044464&zap_placement=139461X1652161Xad5d7f4e7acc091f994039049edd5ba7', 
            pants:'slim jeans black', 
            pantsprice: '$328',
            pantsurl: 'https://www2.hm.com/en_us/productpage.0636207006.html',
            shoes: 'air force one star pack',
            shoesprice: '$328',
            shoesurl: 'https://www.goat.com/sneakers/air-force-1-low-all-star-swoosh-pack-2018-ah8462-102',
            outfitinfo: 'This outfit uses a Chrome Heart Denim jacket, but it currently sold out. Linked is another alternative.',
            outfittype: 'This outfit is a casual look.'
        },
        {
            id: 12, 
            name: 'Ovo Vibes', 
            torso: 'ovo black tee',
            torsoprice: '$58',
            torsourl: 'https://us.octobersveryown.com/collections/t-shirts/products/ovo-essentials-tshirt-sd-black', 
            pants:'blue slim jeans', 
            pantsprice: '13.99$',
            pantsurl: 'https://www2.hm.com/en_us/productpage.0751994003.html',
            shoes: 'Air Jordan 5 Tokyo',
            shoesprice: '$4,500',
            shoesurl: 'https://www.flightclub.com/air-jordan-5-retro-t23-sample-vrsty-mz-anthrct-wlf-gry-blk-802724?utm_source=google_int&utm_medium=google_shopping_int&utm_campaign=1072630453_50626141377&utm_content=253990353042_&utm_term=886059533122&gclid=CjwKCAiAi_D_BRApEiwASslbJxrOM0wqzB0oavgKg_p3BiK6xOY5gxBiMQuHgf_fM1yH745S8ZKfQBoCNKYQAvD_BwE',
            outfitinfo: 'IG Outfit with Drake wearing his courtside look.',
            outfittype: 'Streetwear look in going out in any occasion.'
        }
    ],
    kylie: [
        {
            id: 3, 
            name: 'Kylie Instagram', 
            torso: 'CITY OF CHAMPIONS CREW', 
            torsoprice: '$185',
            torsourl: 'https://cherryla.com/products/city-of-champions-crew', 
            pants:'CITY OF CHAMPIONS SWEATPANTS', 
            pantsprice: '$185',
            pantsurl: 'https://cherryla.com/products/city-of-champions-sweats',
            shoes: 'AIR JORDAN 4 RETRO RED METALLIC',
            shoesprice: '$360',
            shoesurl: 'https://www.flightclub.com/air-jordan-4-retro-white-university-red-metallic-silver-156054',
            outfitinfo: 'Instagram outfit Kylie worn, a post displaying fire retro matching a sporty look.',
            outfittype: 'This is a streetwear cozy look. Whether gym, or going for a casual walk.'
        },
        {
            id: 31, 
            name: 'Kylie Pink Look', 
            torso: 'Drawstring Solid Hoodie', 
            torsoprice: '$14',
            torsourl: 'https://us.shein.com/Drawstring-Detail-Solid-Hoodie-p-582868-cat-1773.html?ref=cj&ref=www&affiliateID=7375765_4206777&url_from=cj.com&cjevent=e4dd7d4c498e11eb80a209490a240610&rep=dir&ret=us', 
            pants:'FEAR OF GOD ESSENTIALS Pink Sweatpants Blush', 
            pantsprice: '$300',
            pantsurl: 'https://stockx.com/fear-of-god-essentials-pink-sweatpants-blush?utm_source=af&utm_medium=imp&utm_campaign=123201&impactSiteId=0RDT9RwvSxyLUMxwUx0Mo3bwUkEy2-RdR0t0UA0&clickid=0RDT9RwvSxyLUMxwUx0Mo3bwUkEy2-RdR0t0UA0&utm_term=0RDT9RwvSxyLUMxwUx0Mo3bwUkEy2-RdR0t0UA0&utm_content=104620_530344&irgwc=1',
            shoes: 'air force one star pack',
            shoesprice: '$328',
            shoesurl: 'https://www.goat.com/sneakers/air-force-1-low-all-star-swoosh-pack-2018-ah8462-102',
            outfitinfo: 'Instagram outfit Kylie worn.',
            outfittype: 'Streetwear look for a cozy spring/fall.'
        }
    ],
    billie: [
        {
            id: 12, 
            name: 'Engineered Hoodie', 
            torso: 'Engineered Hoodie Sweatshirt', 
            torsoprice: '$65',
            torsourl: 'https://footdistrict.com/sudadera-con-capucha-jordan-23-engineered-hombre-cj5989-010.html?wgu=11969_54264_16092589479064_efc58aae19&wgexpiry=1617034947&source=webgains&siteid=54264', 
            pants:'Engineered Fleece Joggers', 
            pantsprice: '$70',
            pantsurl: 'https://footdistrict.com/pantalon-jordan-23-engineered-fleece-hombre-cj5999-010.html?wgu=11969_54264_16092587305338_1627d59281&wgexpiry=1617034730&source=webgains&siteid=54264',
            shoes: 'Jordan 1 Retro High Dior',
            shoesprice: '$7,990',
            shoesurl: 'https://stockx.com/air-jordan-1-retro-high-dior?utm_source=af&utm_medium=imp&utm_campaign=10078&impactSiteId=0RDT9RwvSxyLUMxwUx0Mo3bwUkEy1t0RR0t0UA0&clickid=0RDT9RwvSxyLUMxwUx0Mo3bwUkEy1t0RR0t0UA0&utm_term=0RDT9RwvSxyLUMxwUx0Mo3bwUkEy1t0RR0t0UA0&utm_content=stealherstyle.net_530344&irgwc=1',
            outfitinfo: 'Outfit for the instagram look',
            outfittype: 'Cozy high end streetwear. Nice to wear to a party.'
        },
        {
            id: 12, 
            name: 'Billie Eilish Knitted', 
            torso: 'Kantonia striped distressed knitted sweater', 
            torsoprice: '$420',
            torsourl: 'https://www.matchesfashion.com/us/products/1372171?qxjkl=tsid:38929|cgn:TnL5HPStwNw&c3ch=LinkShare&c3nid=TnL5HPStwNw&utm_source=linkshare&utm_medium=affiliation&utm_campaign=us&utm_content=TnL5HPStwNw&rffrid=aff.linkshare.2116208.37420', 
            pants:'Sweatpant Valley Sky', 
            pantsprice: '$175',
            pantsurl: 'https://ladetresse.com/products/the-half-half-sweatpant-valley-sky-malibu-sand?variant=31666806325300&utm_medium=cpc&utm_source=google&utm_campaign=Google%20Shopping&gclid=EAIaIQobChMI89iCq9Lz7QIVD9vACh2SYAMCEAYYASABEgJ5aPD_BwE',
            outfitinfo: 'Billie Eilish Instagram look witht he knitted long sleeves',
            outfittype: 'High end cozy look to the overall day.'
        },
        {
            id: 12, 
            name: 'Billie Eilish Leave Me Alone', 
            torso: 'Leave Me Alone Tee', 
            torsoprice: '$40',
            torsourl: 'https://whatever21.net/collections/ss19-leave-me-alone/products/leave-me-alone-pink-camo-tee', 
            pants:'Leave Me Alone Tattoo Jeans', 
            pantsprice: '$225',
            pantsurl: 'https://whatever21.net/collections/ss19-leave-me-alone/products/leave-me-alone-tattoo-jeans',
            shoes:'Reebok x Atmos Instapump Fury OG Sneakers', 
            shoesprice: '$179',
            shoesurl: 'https://www.endclothing.com/row/reebok-x-atmos-instapump-fury-og-triple-white-v63458.html',
            outfitinfo: 'Billie Eilish Look with the Leave Me Alone Brand',
            outfittype: 'Streetwear look with a punk style. Definitely something to wear to a festival.'
        }
    ],
    justin: [
        {
            id: 12, 
            name: 'Drew Style',
            torso: 'Drew Orange Hoodie', 
            torsoprice: '$225',
            torsourl: 'https://www.grailed.com/listings/19919736-drew-house-mascot-hoodie', 
            pants:'acid wash fleece sweatpant', 
            pantsprice: '$225',
            pantsurl: 'https://www.alexanderwang.com/ca-en/acid-wash-dense-fleece-sweatpant+192722066720.html?utm_campaign=affiliazione_us&utm_content=10&utm_medium=affiliate&utm_source=FlexOffers.com&siteID=PPkX79_c.b0-t_kTIj2lkpCwPa8X25ofag&ranMID=36828&ranEAID=PPkX79%2Fc*b0&ranSiteID=PPkX79_c.b0-t_kTIj2lkpCwPa8X25ofag',
            shoes:'addidas white ultraboost', 
            shoesprice: '$122',
            shoesurl: 'https://www.flightclub.com/ultraboost-4-0-footwear-white-footwear-white-footwear-white-803261',
            outfitinfo: 'A mix of Justin Bieber wear from the Drew Brand.',
            outfittype: 'Streetwear look for a cozy day.'
        },
        {
            id: 12, 
            name: 'Instagram look', 
            torso: 'essentials hoodie black', 
            torsoprice: '$500',
            torsourl: 'https://stockx.com/fear-of-god-essentials-tmc-crenshaw-hoodie-black?utm_source=af&utm_medium=imp&utm_campaign=123201&impactSiteId=2Yzx1jwoqxyLUMxwUx0Mo3bwUkEUQcVVR0t0UA0&clickid=2Yzx1jwoqxyLUMxwUx0Mo3bwUkEUQcVVR0t0UA0&utm_term=2Yzx1jwoqxyLUMxwUx0Mo3bwUkEUQcVVR0t0UA0&utm_content=104620_530344&irgwc=1', 
            pants:'joggers stripes', 
            pantsprice: '70$',
            pantsurl: 'https://www.depop.com/products/caldon_96-palace-basically-a-jogger-black/',
            shoes:'New Balance 997', 
            shoesprice: '+$148',
            shoesurl: 'https://www.flightclub.com/997-blue-varsity-orange-151842',
            outfitinfo: 'Black Hoodie, and a laid back look.',
            outfittype: 'Streetwear look, good for a gym day.'
        }
    ],
    postmalone: [
        {
            id: 12, 
            hat: 'Dallas Cowboys New Era',
            hatprice: '$25.99',
            haturl: 'https://www.fanatics.com/nfl/dallas-cowboys/dallas-cowboys-new-era-neo-39thirty-flex-hat-realtree-xtra-camo/navy/o-1383+t-92044651+p-91180335358+z-9-947191748?irclickid=SaPxcz1mnxyLT1A07OwzdzZ-UkEUV0ypR0t0UA0&irgwc=1&_s=afl_impact&utm_source=Impact&utm_medium=affiliates&SSAID=123201',
            name: 'Winter Look', 
            torso: 'Superdry Sports Puffer Jacket', 
            torsoprice: '$119.95',
            torsourl: 'https://www.superdry.com/us/products?product_id=3964850&source=googleshopping&locale=en-US&nst=0&gclid=EAIaIQobChMIv6P2gNiV7gIVhtrICh2BhgS-EAQYAiABEgJp0PD_BwE&gclsrc=aw.ds', 
            pants:'Double-Knit Joggers Pants', 
            pantsprice: '$125.00',
            pantsurl: 'https://www.macys.com/shop/product/polo-ralph-lauren-mens-big-tall-double-knit-joggers-pants?ID=6131413&CategoryID=89&swatchColor=Black',
            shoes:'UGG Classic Short Boots',
            shoesprice: '$500',
            shoesurl: 'https://stockx.com/fr-fr/ugg-classic-short-boot-mastermind-world-black?utm_source=af&utm_medium=imp&utm_campaign=123201&impactSiteId=yk9xtO1mGxyLUfBwUx0Mo3bwUkEUV3wFR0t0UA0&clickid=yk9xtO1mGxyLUfBwUx0Mo3bwUkEUV3wFR0t0UA0&utm_term=yk9xtO1mGxyLUfBwUx0Mo3bwUkEUV3wFR0t0UA0&utm_content=104620_530344&irgwc=1',
            outfitinfo: 'Look from a instagram post',
            outfittype: 'Winter streetwear look. Eastcoast vibes.'
        },
        {
            id: 12, 
            name: 'Cowboy Jersey', 
            torso: 'Dallas Cowboys Jersey', 
            torsoprice: '$100',
            torsourl: 'https://www.nike.com/t/dallas-cowboys-amari-cooper-mens-game-football-jersey-LhG0nM/468950-118', 
            pants:'H and M Ripped Blue jeans', 
            pantsprice: '19.99$',
            pantsurl: 'https://www.vans.com/shop/authentic-true-white',
            shoes:'Vans White Au­then­tic', 
            shoesprice: '$50.00',
            shoesurl: 'https://www.vans.com/shop/authentic-true-white',
            outfitinfo: 'Codwboy jersey from instagram post.',
            outfittype: 'Streetwear with a sports look.'
        }
    ]
  };
  
  export { artistslist };
  