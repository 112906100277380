import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";

import { vintagelist } from "../vintage-list";

import { Location } from '@angular/common';

@Component({
  selector: 'app-vintage-items',
  templateUrl: './vintage-items.component.html',
  styleUrls: ['./vintage-items.component.scss']
})

export class VintageItemsComponent implements OnInit {
  vintagelist = vintagelist;
  path: string;

  constructor(private route: ActivatedRoute, private location: Location) {

   }
   
   goBack() {
    this.location.back();
  } 

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.path = params.get("vintageId")
    })
  }

}
