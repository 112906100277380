import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';


import { Match } from '../matcher';
import { MATCHER } from '../products';
import { HATER } from '../hats';
import { PANTS } from '../pants';

// function hello() {
//     alert('Hello!!!');
// }

@Component({
    selector: 'app-matcher',
    templateUrl: './matcher.component.html',
    styleUrls: ['./matcher.component.scss'],
})

export class MatcherComponent{


    showDiv = {
        hats : false,
        shirts : false,
        pants : false
      }

    products = MATCHER;
    hats = HATER;
    pants = PANTS;



    selectedMatchHat: Match;
    OnSelectHat(match: Match): void {
        this.selectedMatchHat = match;
    }

    selectedMatchShirt: Match;
    OnSelectShirt(match: Match): void {
        this.selectedMatchShirt = match;
    }

    selectedMatchPants: Match;
    OnSelectPants(match: Match): void {
        this.selectedMatchPants = match;
    }
    
    ngOnInit(){
        // hello()

    }

    constructor(

    ){}
}