import { Component, OnInit } from '@angular/core';

import { artistslist } from "../artists-list"

@Component({
  selector: 'app-artists',
  templateUrl: './artists.component.html',
  styleUrls: ['./artists.component.scss']
})

export class ArtistsComponent implements OnInit {

  objectKeys = Object.keys;
  
  artistslist = artistslist;
  
  constructor() { }

  ngOnInit(): void {
    console.log(Object.keys);
  }
}