<div class="celebrity">
   <h1 class="sr-only">Celebrity</h1>
    <div class="mt-4 d-flex align-items-center">
       <h2 class="mb-3">Popular Outift Trends</h2>
    </div>
    <div class="row">
       <!-- <div *ngFor="let key of objectKeys(artistslist)">
          this in <a [routerLink]="key">{{key}}</a>
          <img src="assets/images/{{key}}.png" alt="">
          </div> -->
       <!-- <div *ngFor="let key of objectKeys(artistslist)" class="col-xl-3 col-lg-6 col-md-6">
          <a class="card bunny"  [routerLink]="key">
          <h3>{{key}}</h3>
          <img class="w-100" src="assets/images/{{key}}.png" alt="">
          </a>
          </div> -->
       <div class="col-xl-3 col-lg-6 col-6">
          <a class="card bunny" routerLink="badbunny">
             <h3>Bad Bunny</h3>
             <img class="w-100" src="assets/images/bad-bunny.png" alt="">
          </a>
       </div>
       <div class="col-xl-6 col-lg-6 col-6">
          <a class="card kylie" routerLink="kylie">
             <h3>Kylie Jenner</h3>
             <img class="w-100" src="assets/images/kylie.png" alt="">
          </a> 
       </div>
       <div class="col-xl-3 col-lg-6 col-6">
          <a routerLink="billie" class="card billie" href="#">
             <h3>Billie Eilish</h3>
             <img class="w-100" src="assets/images/be.png" alt="">
          </a>
       </div>
       <div class="col-xl-3 col-lg-6 col-6">
          <a class="card drake" routerLink="drake">
             <h3>Drake</h3>
             <img class="w-100" src="assets/images/drake.png" alt="">
          </a>
       </div>
       <div class="col-xl-3 col-lg-6 col-6">
          <a class="card justin" routerLink="justin">
             <h3>Justin Bieber</h3>
             <img class="w-100" src="assets/images/justin.png" alt="">
          </a>
       </div>
       <div class="col-xl-6 col-lg-6 col-6">
          <a class="card malone" routerLink="postmalone">
             <h3>Post Malone</h3>
             <img class="w-100" src="assets/images/postmalone.png" alt="">
          </a>
       </div>
    </div>
    <div class="celebrity-list">
      <h2 class="mt-4">Celebrity Outifts</h2>
      <div class="row">
        <div *ngFor="let key of objectKeys(artistslist)" class="col-xl-3 col-lg-6 col-6">
           <a class="card"  [routerLink]="key">
           <h3>{{key}}</h3>
           </a>
           </div>
      </div>
    </div>
    <!-- <h2 class="mt-5 mb-3">Celebrity Types</h2>
    <div class="row celebrity-type">
       <div class="col-xl-3 col-lg-6"><a class="card" href="items.html">Artist</a></div>
       <div class="col-xl-3 col-lg-6"><a class="card" href="#">Actor</a></div>
       <div class="col-xl-3 col-lg-6"><a class="card" href="#">Influencer</a></div>
       <div class="col-xl-3 col-lg-6"><a class="card" href="#">YouTuber</a></div>
    </div> -->
 </div>