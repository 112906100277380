<div class="main container mt-4">
    <h1 class="sr-only">Dating</h1>
    <a routerLink="Dinner Date" class="banner">
        <i class="fas fa-mug-hot"></i>
        <div>
            <h2>Popular Pick</h2>
            <p>What to wear First date?</p>
        </div>
        <!-- <img src="assets/images/highend.png" alt=""> -->
    </a>
    <div _ngcontent-six-c91="" class="d-flex align-items-center">
        <h2 _ngcontent-six-c91="" class="mb-3">Outifts for Dating</h2>
    </div>
    <div class="dating">
        <div class="dating-list">
            <div class="row">
                <!-- <div class="col-md-3" *ngFor="let key of objectKeys(datinglist)">
                    <a class="card" [routerLink]="key">
                        <i class="fas fa-mobile-alt"></i>
                        <p>{{key}}</p>
                    </a>
                </div> -->
                <div class="col-xl-3 col-md-4 col-6 mb-4">
                    <a class="card" routerLink="First Date">
                        <i class="fas fa-mug-hot"></i>
                        <p>First Date</p>
                    </a>
                </div>
                <div class="col-xl-3 col-md-4 col-6 mb-4">
                    <a class="card" routerLink="Dinner Date">
                        <i class="fas fa-wine-glass-alt"></i>
                        <p>Dinner Date</p>
                    </a>
                </div>
                <div class="col-xl-3 col-md-4 col-6 mb-4">
                    <a class="card" routerLink="Cold Approach">
                        <i class="fab fa-redhat"></i>
                        <p>Cold Appoach</p>
                    </a>
                </div>
                <div class="col-xl-3 col-md-4 col-6 mb-4">
                    <a class="card" routerLink="Dating App">
                        <i class="fas fa-mobile-alt"></i>
                        <p>Dating Apps</p>
                    </a>
                </div>
            </div>
        </div>
    </div>    
</div>
