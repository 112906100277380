import { Component, OnInit } from '@angular/core';

import { streetwearlist } from "../streetwear-list";

@Component({
  selector: 'app-streetwear',
  templateUrl: './streetwear.component.html',
  styleUrls: ['./streetwear.component.scss']
})
export class StreetwearComponent implements OnInit {

  objectKeys = Object.keys;

  streetwearlist = streetwearlist;

  constructor() { }

  ngOnInit(): void {
  }

}
