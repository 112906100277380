import { Component, OnInit } from '@angular/core';

import { streetwearlist } from "../../streetwear-list";

import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-streetwear-items',
  templateUrl: './streetwear-items.component.html',
  styleUrls: ['./streetwear-items.component.scss']
})

export class StreetwearItemsComponent implements OnInit {
streetwearlist = streetwearlist;
path: string;

  constructor(private route: ActivatedRoute, private location: Location) { }

  goBack() {
    this.location.back();
  } 

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.path = params.get("streetwearId");
    })
  }

}
