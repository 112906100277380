<h1 class="sr-only">Streetwear</h1>
<h2 class="mt-4">Streetwear Outfits</h2>
<div class="row">
    <div class="col-xl-3 col-lg-4 col-md-4 col-6">
        <a routerLink="hypebeast"  class="card-image card hypebeast">
            <h3>Hypebeast</h3>
            <img src="assets/images/thumbnail/hypebeast.png" class="w-100" alt="">
        </a>
    </div>
    <!-- <div class="col-xl-3 col-lg-4 col-md-4 col-6">
        <a routerLink="supreme"  class="card-image card culture">
            <h3>Culture</h3>
            <img src="assets/images/thumbnail/culture.png" class="w-100" alt="">
        </a>
    </div>  
    <div class="col-xl-3 col-lg-4 col-md-4 col-6">
        <a routerLink="supreme"  class="card-image card techwear">
            <h3>Skater</h3>
            <img src="assets/images/thumbnail/skater.png" class="w-100" alt="">
        </a>
    </div>  
    <div class="col-xl-3 col-lg-4 col-md-4 col-6">
        <a routerLink="supreme"  class="card-image card upcoming">
            <h3>Up and Coming</h3>
            <img src="assets/images/80s.png" class="w-100" alt="">
        </a>
    </div>     -->
</div>