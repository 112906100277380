<div class="d-flex align-items-center my-4">
    <button class="back" (click)="goBack()"><i class="fas fa-arrow-left"></i></button>
      <h1 class="mb-0">{{product.name}}</h1>
    </div>
  <div class="row">
    <div class="col-xl-7">
      <div class="clothing tab-content">
        <div *ngIf="product.hat">
          <a target="_blank" class="person-style" href={{product.haturl}}>
            <img src="assets/images/{{product.hat}}.png" alt="">
            <div class="person-content">
              <h2>{{product.hat}}</h2>
              <p>{{product.hatprice}}</p>   
            </div>
          </a>
        </div>
        <div *ngIf="product.glasses">
          <a target="_blank"  class="person-style" href={{product.glassesurl}}>
            <img src="assets/images/{{product.glasses}}.png" alt="">
            <div class="person-content">
              <h2>{{product.glasses}}</h2>
              <p>{{product.glassesprice}}</p>   
            </div>
          </a>
        </div>
        <div *ngIf="product.torso">
          <a target="_blank" class="person-style" href={{product.torsourl}}>
            <img src="assets/images/{{product.torso}}.png" alt="">
            <div class="person-content">
              <h2>{{product.torso}}</h2>
              <p>{{product.torsoprice}}</p>
            </div>
          </a>
        </div>
        <div *ngIf="product.pants" >
          <a target="_blank" class="person-style" href="{{product.pantsurl}}">
            <img src="assets/images/{{product.pants}}.png" alt="">
            <div class="person-content">
              <h2>{{product.pants}}</h2>
              <p>{{product.pantsprice}}</p>
            </div>
          </a>
        </div>
          <div *ngIf="product.shoes">
            <a target="_blank" class="person-style" href="{{product.shoesurl}}">
              <img src="assets/images/{{product.shoes}}.png" class="shoe" alt="">
              <div class="person-content">
                <h2>{{product.shoes}}</h2>
                <p> {{product.shoesprice}}</p>
              </div>
            </a>
          </div>
      </div>        
    </div>
    <div class="col-xl-5">
      <div class="card-note">
        <div class="d-flex mb-1"><i class="fas fa-tshirt"></i>
          <h2>{{product.name}}</h2>
        </div>
        <p>{{product.outfitinfo}}</p>
      </div>
      <div class="card card-note bg-white shadow">
        <div class="d-flex mb-1"><i class="fas fa-tshirt"></i>
          <h2 class="text-dark">Outfit Type</h2>
        </div>
        <p>{{product.outfittype}}</p>
      </div>
    </div>      
  </div>