import { Match } from './matcher';

export const PANTS: Match[] = [
    {
        id: 121,
        name: 'joggers',
        url: 'TeddyFresh.com/shirt'
    },
    {
        id: 1221,
        name: 'dark jeans',
        url: 'TeddyFresh.com/longsleeve'
    },
];