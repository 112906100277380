import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";

import {artistslist } from '../artists-list';

import { Location } from '@angular/common';

@Component({
  selector: 'app-artists-items',
  templateUrl: './artists-items.component.html',
  styleUrls: ['./artists-items.component.scss']
})
export class ArtistsItemsComponent implements OnInit {
  artistslist = artistslist;
  path: string;

  constructor(private route: ActivatedRoute, private location: Location) { 
  
  } 

  goBack() {
    this.location.back();
  } 

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.path = params.get("artistsId");
      console.log(this.path)

    });
  }

}
