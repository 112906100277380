      <!-- <button mat-icon-button color="warn" aria-label="Example icon button with a heart icon">
        <mat-icon>favorite</mat-icon>
      </button> -->
<div class="d-flex mt-4">
  <div class="btn" (click)="showDiv.hats = !showDiv.hats; showDiv.pants = false; showDiv.shirts = false">
    Hats
  </div>

  <div class="btn" (click)="showDiv.shirts = !showDiv.shirts; showDiv.hats = false; showDiv.pants=false">
  Shirts
  </div>

  <div class="btn" (click)="showDiv.pants = !showDiv.pants; showDiv.hats = false; showDiv.shirts= false">
    Pants
  </div> 
  <button class="ml-auto mat-elevation-z0" mat-raised-button color="primary">Save Snip</button>
</div>
<div class="matcher">
  <div class="matcher-content">
    <div class="clothing">
      <div *ngIf="showDiv.hats">
        <div class="carding" *ngFor="let hat of hats" (click)="OnSelectHat(hat)">
          <img src='assets/images/hats/{{hat.name}}.png' class="img-fluid">
        </div> 
      </div>
      <div *ngIf="showDiv.shirts">
        <div class="carding" *ngFor="let product of products" (click)="OnSelectShirt(product)">
          <img src='assets/images/torso/{{product.name}}.png' class="img-fluid">
        </div>
      </div>
      <div *ngIf="showDiv.pants">
        <div class="carding" *ngFor="let pant of pants" (click)="OnSelectPants(pant)">
          <img src='assets/images/pants/{{pant.name}}.png' class="img-fluid">
        </div>
      </div>
    </div>
    <div class="outline">
      <a href='{{selectedMatchHat.url}}' target="_blank" class="person-style" *ngIf="selectedMatchHat">
        <img src='assets/images/hats/{{selectedMatchHat.name}}.png' class="img-fluid">
        <div class="person-content">
              <h2>{{selectedMatchHat.name}}</h2> 
        </div>
      </a>     
        <a  href='{{selectedMatchShirt.url}}' target="_blank" class="person-style" *ngIf="selectedMatchShirt">
          <img src='assets/images/torso/{{selectedMatchShirt.name}}.png' class="img-fluid">
          <div class="person-content">
            <h2>{{selectedMatchShirt.name}}</h2> 
          </div>
        </a>     
        <a href='{{selectedMatchPants.url}}' target="_blank" class="person-style" *ngIf="selectedMatchPants">
          <img src='assets/images/pants/{{selectedMatchPants.name}}.png' class="img-fluid">
          <div class="person-content">
                <h2>{{selectedMatchPants.name}}</h2> 
          </div>
        </a>   
    </div>
  </div>
</div>