<div class="home">
  <div class="notification mt-3">
    <h1>Clothes Matcher</h1>
    <p class="mb-4">Select some of the best styles of clothing. From celebrity wear, to whats worn in a dating. Get ideas on what to wear in many different occasions.</p>
    <div>
        <a href="#categories" class="Categories btn btn-primary">Categories</a>
    </div>
  </div>
  <div class="home-trending">
    <h2 class="mt-5">Trending</h2>
    <div class="row">
      <div class="col-lg-4 mb-4 col-6">
        <a [routerLink]="['/artists']" class="card celebrity">
          <h3>Celebrity</h3>
          <div>
            <img src="assets/images/palaceyellow.png" width="300" height="364" alt="">
          </div>
        </a>
      </div>
      <div class="col-lg-8 mb-4 col-6">
        <a [routerLink]="['/streetwear']" class="card streetwear">
          <h3>Streetwear</h3>
          <div>
            <img src="assets/images/greenshort.png" width="300" height="346.8" alt="">
          </div>
        </a>
      </div>
      <!-- <div class="col-md-4 mb-4 col-6">
        <a href="#" class="card dating">
          <h3>Dating</h3>
          <div>
            <img src="assets/images/palacelong.png" alt="">
          </div>
        </a>
      </div> -->
    </div>
  </div>
  <div class="home-categories">
    <a name="categories" aria-hidden="true"></a>
    <h2 class="mt-4">Categories</h2>
    <div class="row">
      <div class="col-xl-3 col-lg-4 col-md-4 col-6">
        <a [routerLink]="['/artists']" class="card-image card celebrity">
          <h3>Celebrity</h3>
          <img src="assets/images/be.png" class="w-100" alt="">
        </a>
      </div>
      <div class="col-xl-3 col-lg-4 col-md-4 col-6">
        <a [routerLink]="['/dating']" class="card-image card dating">
          <h3>Dating</h3>
          <img src="assets/images/dating.png" class="w-100" alt="">
        </a>   
      </div>
      <div class="col-xl-3 col-lg-4 col-md-4 col-6">
        <a [routerLink]="['/vintage']" class="card-image card vintage">
          <h3>Vintage</h3>
          <img src="assets/images/vintage.png" class="w-100" alt="">
        </a>   
      </div>
      <div class="col-xl-3 col-lg-4 col-md-4 col-6">
        <a [routerLink]="['/streetwear']" class="card-image card streetwear">
          <h3>Streetwear</h3>
          <img src="assets/images/streetwear.png" class="w-100" alt="">
        </a>
      </div>
      <!-- <div class="col-xl-3 col-lg-4 col-md-4 col-6">
        <a [routerLink]="['/artists']" class="card-image card career">
          <h3>Career</h3>
          <img src="assets/images/career.png" class="w-100" alt="">
        </a>
      </div> -->
      <!-- <div class="col-xl-3 col-lg-4 col-md-4 col-6">
        <div class="card-image card sports">
          <h3>Sports</h3>
          <img src="assets/images/sports.png" class="w-100" alt="">
        </div>   
      </div>
      <div class="col-xl-3 col-lg-4 col-md-4 col-6">
        <div class="card-image card highend">
          <h3>High End</h3>
          <img src="assets/images/highend.png" class="w-100" alt="">
        </div>   
      </div>
      <div class="col-xl-3 col-lg-4 col-md-4 col-6">
        <div class="card-image card parties">
          <h3>Parties</h3>
          <img src="assets/images/parties.png" class="w-100" alt="">
        </div>
      </div> -->
    </div>
  </div>
</div>