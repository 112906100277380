import { Component, OnInit } from '@angular/core';

import { datinglist } from "../dating-list";

@Component({
  selector: 'app-dating',
  templateUrl: './dating.component.html',
  styleUrls: ['./dating.component.scss']
})

export class DatingComponent implements OnInit {

  objectKeys = Object.keys;

  datinglist = datinglist;

  constructor() { }

  ngOnInit(): void {
  }

}
