<div class="home">
  <h1 class="sr-only">Vintage</h1>
    <div class="home-categories">
      <h2 class="mt-4">Vintage Outfits</h2>
      <div class="row">
        <div class="col-xl-3 col-lg-4 col-md-4 col-6">
          <a routerLink="80s"  class="card-image card celebrity">
            <h3>80's</h3>
            <img src="assets/images/80s.png" class="w-100" alt="">
          </a>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-4 col-6">
          <a  routerLink="90s" class="card-image card dating">
            <h3>90's</h3>
            <img src="assets/images/colorblock.png" class="w-100" alt="">
          </a>   
        </div>
        <div class="col-xl-3 col-lg-4 col-md-4 col-6">
          <a  routerLink="20s" class="card-image card vintage">
            <h3>20's</h3>
            <img src="assets/images/2000s.png" class="w-100" alt="">
          </a>   
        </div>
        <div class="col-xl-3 col-lg-4 col-md-4 col-6">
          <a routerLink="10s" class="card-image card streetwear">
            <h3>10's</h3>
            <img src="assets/images/streetwear.png" class="w-100" alt="">
          </a>
        </div>
      </div>
    </div>
  </div>