import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";

import { datinglist } from '../dating-list';

import { Location } from '@angular/common';

@Component({
  selector: 'app-dating-items-details',
  templateUrl: './dating-items-details.component.html',
  styleUrls: ['./dating-items-details.component.scss']
})

export class DatingItemsDetailsComponent implements OnInit {
  datinglist = datinglist;
  path:string;
  pathsub: string;

  product;

  constructor(
    private route: ActivatedRoute,
    private location: Location
  ) { 

  } 

  goBack() {
    this.location.back();
  }  

  ngOnInit(){
    this.route.paramMap.subscribe(params => {
      this.path = params.get("datingId");
      this.pathsub = params.get("datingType");

      this.product = datinglist[this.path][+params.get('datingType')];
    })
  }

}
