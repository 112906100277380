<div class="categories">
    <div class="mt-4 d-flex align-items-center">
      <h2>Popular Genres</h2>
      <div class="ml-auto"><a class="text-primary" href="#">See More</a></div>
    </div>
    <div class="row categories-popular">
      <div class="col-xl-3 col-lg-4 col-md-4"><a class="card categories-popular-club" href="#">
          <h3>Clothes for Clubbing</h3></a></div>
      <div class="col-xl-6 col-lg-4 col-md-4"><a class="card categories-popular-bbq" href="#">
          <h3>Clothes for BBQs</h3></a></div>
      <div class="col-xl-3 col-lg-4 col-md-4"><a class="card categories-popular-dating" href="#">
          <h3>Clothes for Dating</h3></a></div>
      <div class="col-xl-3 col-lg-4 col-md-4"><a class="card categories-popular-approach" href="#">
          <h3>Clothes for Approaches</h3></a></div>
      <div class="col-xl-3 col-lg-4 col-md-4"><a class="card categories-popular-vibes" href="#">
          <h3>Clothes for Chill Vibes</h3></a></div>
      <div class="col-xl-6 col-lg-4 col-md-4"><a class="card categories-popular-interviews" href="#">
          <h3>Interviews</h3></a></div>
    </div>
    <h2 class="mt-4">Genres</h2>
    <div class="row categories-genre">
      <div class="col-md-4"><a class="card" href="#"><i class="fas fa-user-tie"></i>
          <h3>Casual</h3>
          <p>look to work casual</p></a></div>
      <div class="col-md-4"><a class="card" href="#"><i class="fas fa-baseball-ball"></i>
          <h3>Sporty</h3>
          <p>Look to swear sporty</p></a></div>
      <div class="col-md-4"><a class="card" href="items.html"><i class="fas fa-road"></i>
          <h3>Streetwear</h3>
          <p>Look to work casual</p></a></div>

      <div class="col-md-4"><a class="card"  [routerLink]="['/artists']" routerLinkActive="active"><i class="fas fa-user-tie"></i>
          <h3>Celebrity</h3>
          <p>look to work casual</p></a>
        </div>


      <div class="col-md-4"><a class="card" href="#"><i class="fas fa-baseball-ball"></i>
          <h3>Beach</h3>
          <p>Look to swear sporty</p></a></div>
      <div class="col-md-4"><a class="card" href="#"><i class="fas fa-road"></i>
          <h3>LA  </h3>
          <p>Look to work casual</p></a></div>
    </div>
  </div>