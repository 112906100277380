import { Match } from './matcher';

export const MATCHER: Match[] = [
    {
        id: 1,
        name: 'shirt orange',
        url: 'TeddyFresh.com/shirt'
    },
    {
        id: 2,
        name: 'shirt purple',
        url: 'TeddyFresh.com/longsleeve'
    },
    {
        id: 3,
        name: 'shirt red',
        url: 'TeddyFresh.com/shirt'
    },
    {
        id: 32,
        name: 'checkerpurple',
        url: 'TeddyFresh.com/shirt'
    },
    {
        id: 0,
        name: 'Supreme Baseball Jersey',
        url: 'https://www.stadiumgoods.com/supreme-mesh-hooded-l-s-baseball-jerse-ss-20-su8911?flow_enabled=false&gclid=EAIaIQobChMIvrqat5Ti7gIVKf3jBx28Gw53EAQYASABEgJ2cPD_BwE',
    }
];