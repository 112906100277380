const streetwearlist = {
  "hypebeast": [
    {
      id: 0,
      name: "Supreme Team",
      torso: 'Supreme Baseball Jersey',
      torsourl: 'https://www.stadiumgoods.com/supreme-mesh-hooded-l-s-baseball-jerse-ss-20-su8911?flow_enabled=false&gclid=EAIaIQobChMIvrqat5Ti7gIVKf3jBx28Gw53EAQYASABEgJ2cPD_BwE',
      torsoprice: '$220',
      pants:'Supreme Camo Cargo Pant',
      pantsprice: '$635',
      pantsurl: 'https://www.stadiumgoods.com/supreme-camo-cargo-pant-ss-19-stone-island-su6842',
      outfitinfo: 'Rep your team brand, Flex your stand. Supreme', 
      outfittype: 'Streetwear combining sporty, and skater.',
      shoes: 'Air Force One 07 White',
      shoesprice: '$90+',
      shoesurl: 'https://www.nike.com/t/air-force-1-07-mens-shoe-5QFp5Z/CW2288-111?nikemt=true',
    },
    {
      id: 0,
      name: "Wyoming Kanye Tee",
      icon: 'fas fa-mobile-alt',
      torso: 'Kanye West Wyoming Long Sleeve Tee',
      torsoprice: '$69',
      torsourl: 'https://www.stadiumgoods.com/kanye-west-wyoming-long-sleeve-tee-820380883',
      pants:'supreme work pants', 
      pantsprice: '$120',
      pantsurl: 'https://www.stadiumgoods.com/supreme-work-pant-ss-18-su5128',
      outfitinfo: 'Kanye West Tee is straight heat with matching pants. Cant Go wrong with Air Force Ones.', 
      outfittype: 'Streetwear with Music',
      shoes: 'air force one star pack',
      shoesprice: '$90+',
      shoesurl: 'https://www.flightclub.com/air-force-1-07-qs-sail-sail-sail-803394'
    },
    {
      id: 0,
      name: "Atronomical",
      icon: 'fas fa-mobile-alt',
      torso: 'atronomical',
      torsoprice: '$99',
      torsourl: 'https://www.stadiumgoods.com/travis-scott-astro-portrait-t-shirt-cjfn-ss15',
      pants:'Supreme TNF Expedition Pant', 
      pantsprice: '$785',
      pantsurl: 'https://www.stadiumgoods.com/supreme-tnf-expedition-pant-ss-17-su6265',
      outfitinfo: 'Northface is getting more popularized. Mix with a dash of Atronmoical', 
      outfittype: 'Streetwear look. Joggers is comfort.',
      shoes: 'air force one star pack',
      shoesprice: '$90+',
      shoesurl: 'https://www.flightclub.com/air-force-1-07-qs-sail-sail-sail-803394'
    },
    {
      id: 0,
      name: "Anti Socially Pink",
      icon: 'fas fa-mobile-alt',
      torso: 'Anti Social Social Club F1',
      torsoprice: '$61',
      torsourl: 'https://www.grailed.com/listings/19722582-anti-social-social-club-x-undefeated-ds-undftd-x-anti-social-social-club-f1-tee-supreme-bape?gclid=EAIaIQobChMI_7Kt-KyF7wIVhobACh07XwrlEAQYBSABEgIYBvD_BwE&utm_campaign=smart_shopping_us&utm_medium=cpc&utm_source=google&utm_term=sem_nonbranded',
      pants:'Supreme Nike Trail Running Pink Pants',
      pantsprice: '$295$', 
      pantsurl: 'https://www.stadiumgoods.com/supreme-nike-trail-running-pant-nike-fw17-su3024',
      outfitinfo: 'Anti Socially loud, pink giving a 90s feel.', 
      outfittype: 'Streetwear look. Joggers is comfort.',
      shoes: 'air force one star pack',
      shoesprice: '$230+',
      shoesurl: 'https://www.flightclub.com/air-force-1-07-qs-sail-sail-sail-803394'
    },
    {
      id: 0,
      name: "Purple Sauce",
      icon: 'fas fa-mobile-alt',
      torso: 'Supreme The Real Sh*t Tee',
      torsoprice: '$185',
      torsourl: 'https://www.stadiumgoods.com/the-real-sh-t-l-s-tee',
      pants:'Supreme TNF Arc Logo Mountain Pant', 
      pantsprice: '$489',
      pantsurl: 'https://www.stadiumgoods.com/supreme-tnf-arc-logo-mountain-pant-ss-19-su6849',
      outfitinfo: 'Purple and black, meshes really well with Air Force Ones', 
      outfittype: 'Streetwear look. With a some sleek Jeans.',
      shoes: 'Air Force One 07 White',
      shoesprice: '$90+',
      shoesurl: 'https://www.nike.com/t/air-force-1-07-mens-shoe-5QFp5Z/CW2288-111?nikemt=true',
    },
    {
      id: 0,
      name: "Orange Paranoia",
      icon: 'fas fa-mobile-alt',
      torso: 'anti social paranoid tee',
      torsoprice: '$95',
      torsourl: 'https://www.stadiumgoods.com/anti-social-social-club-paranoid-assc-black-logo-t-shi-as0001',
      pants:'Printed Joggers', 
      pantsprice: '29.99$',
      pantsurl: 'https://www2.hm.com/en_us/productpage.0917812004.html',
      outfitinfo: 'Flex that look and express how you feel.', 
      outfittype: 'Streetwear comfort with jogging pants.',
      shoes: 'Air Jordan 4 Orange Metallic',
      shoesprice: '$307+',
      shoesurl: 'https://www.flightclub.com/air-jordan-4-retro-metallic-pack-total-orange-ct8527-114?utm_source=google_int&utm_medium=google_shopping_int&utm_campaign=1072630453_50626141377&utm_content=253990353042_&utm_term=193658231756&gclid=Cj0KCQiA1KiBBhCcARIsAPWqoSqHuq8FsXBoTcEbK0cjB0C9JZkhJp2MAXhIecia78eqFy7nRd96l_QaAhD0EALw_wcB'
    },
    {
      id: 0,
      name: "Mocha Brown",
      icon: 'fas fa-mobile-alt',
      torso: 'Supreme CDG Split Box Logo Tee',
      torsoprice: '$439',
      torsourl: 'https://www.stadiumgoods.com/supreme-cdg-split-box-logo-tee-fw-18-su5671',
      pants:'Supreme Side Logo Track Pant Brown', 
      pantsprice: '295$',
      pantsurl: 'https://www.stadiumgoods.com/supreme-side-logo-track-pant-fw-20-su9946',
      outfitinfo: 'Laid back streetwear look. Mocha colors to stand out.', 
      outfittype: 'Streetwear comfort look',
      shoes: 'Air Jordan 1 Dark Mocha',
      shoesprice: '$380+',
      shoesurl: 'https://www.flightclub.com/air-jordan-1-retro-high-og-sail-dark-mocha-black-black-162310'
    },
    {
      id: 0,
      name: "Olive Knight Supreme",
      icon: 'fas fa-mobile-alt',
      torso: 'Supreme Independent Crewneck',
      torsoprice: '$245',
      torsourl: 'https://www.stadiumgoods.com/supreme-independent-crewneck-fw-17-su3317',
      pants:'Stadium Goods Tracker Pants Navy', 
      pantsprice: '85$',
      pantsurl: 'https://www.stadiumgoods.com/stadium-goods-track-pants-sgs0134',
      outfitinfo: 'This style is all relaxed vibes, can be used on after day gyms, getting coffee, going to malls, even skateboarding.', 
      outfittype: 'Streetwear look. For comfort and casual.',
      shoes: 'NMD R1 Olive Cargo',
      shoesprice: '$250+',
      shoesurl: 'https://www.flightclub.com/nmd-r1-olive-white-black-201498'
    },
    {
      id: 0,
      name: "Sunnyside Up",
      icon: 'fas fa-mobile-alt',
      torso: 'Supreme Cross Box Logo Hoodie',
      torsoprice: '$389',
      torsourl: 'https://www.stadiumgoods.com/supreme-cross-box-logo-hoodie-fw-20-su9744',
      pants:'Stadium Good Nylon Reflective Jogger Pants', 
      pantsprice: '95$',
      pantsurl: 'https://www.stadiumgoods.com/stadium-goods-nylon-reflective-jogger-pants-sgs0244',
      outfitinfo: 'Flex on a rainy day, with velcro pants.', 
      outfittype: 'Streetwear look. Comforting on rainy days and even sunny.',
      shoes: 'NMD_R1 Primeknit Yellow',
      shoesprice: '$165+',
      shoesurl: 'https://www.flightclub.com/nmd_r1-primeknit-eqt-yellow-s23749'
    },
    {
      id: 0,
      name: "Easy Blue",
      icon: 'fas fa-mobile-alt',
      torso: 'Yankees Blue Box Logo Tee',
      torsoprice: '$979',
      torsourl: 'https://www.stadiumgoods.com/supreme-yankees-box-logo-tee-su0132',
      pants:'Stadium Goods Blue Track Pants', 
      pantsprice: '85$',
      pantsurl: 'https://www.stadiumgoods.com/stadium-goods-track-pants-sgs0135',
      outfitinfo: 'Summer and Spring look.', 
      outfittype: 'Streetwear for comforting days.',
      shoes: 'Air Jordan 1 Storm Blue',
      shoesprice: '$380+',
      shoesurl: 'https://www.flightclub.com/air-jordan-1-retro-high-og-white-storm-blue-800118'
    },
    {
      id: 0,
      name: "Picture Me Green",
      icon: 'fas fa-mobile-alt',
      torso: 'Supreme Harvest Longsleeve',
      torsoprice: '$120',
      torsourl: 'https://www.stadiumgoods.com/supreme-harvest-l-s-tee-fw-20-su9424',
      pants:'AE Cozy AirFlex Denim Jogger', 
      pantsprice: '19.99$',
      pantsurl: 'https://www.ae.com/us/en/p/men/slim-fit-jeans/denim-joggers/ae-cozy-airflex-denim-jogger/1114_5688_423?ip=off&menu=cat4840004&gclsrc=aw.ds&&cid=PLA_AE_NonBrand_Mens_Jeans_Slim&gclid=Cj0KCQiA1KiBBhCcARIsAPWqoSqDeyoOs6QyJUDRUtwyACFqL7iGhkrY1NBFWvi_g3ZnY894sCYxyGkaAnOMEALw_wcB',
      outfitinfo: 'Wear to the stores, chilling with friends, and anywhere casual in friend environment.', 
      outfittype: 'Streetwear Picture Look.',
      shoes: 'Pharrell x Billionaire Boys Club x NMD Green Plaid',
      shoesprice: '$299+',
      shoesurl: 'https://www.goat.com/sneakers/pharrell-x-billionaire-boys-club-x-nmd-human-race-trail-green-plaid-ef7388',
    },
    {
      id: 0,
      name: "Loud Supreme",
      icon: 'fas fa-mobile-alt',
      torso: 'Supreme Invert Tee',
      torsoprice: '$85',
      torsourl: 'https://www.stadiumgoods.com/supreme-invert-s-s-top-ss-20-su8924',
      pants:'Stadium Goods Blue Track Pants', 
      pantsprice: '85$',
      pantsurl: 'https://www.stadiumgoods.com/stadium-goods-track-pants-sgs0135',
      outfitinfo: '2000s style is making a huge comeback with light/loud colors.', 
      outfittype: 'Streetwear look in comforting occasions.',
      shoes: 'Air Force One 07 White',
      shoesprice: '$90+',
      shoesurl: 'https://www.nike.com/t/air-force-1-07-mens-shoe-5QFp5Z/CW2288-111?nikemt=true',
    },
    {
      id: 0,
      name: "Supreme Green",
      icon: 'fas fa-mobile-alt',
      torso: 'Supreme Nuova York Tee',
      torsoprice: '$100',
      torsourl: 'https://www.grailed.com/listings/20294377-supreme-supreme-nuova-york-tee-black-large-fw20?gclid=EAIaIQobChMIg4qSxbCF7wIVCuPACh0i9QSgEAsYAiABEgLXDvD_BwE&utm_campaign=smart_shopping_us&utm_medium=cpc&utm_source=google&utm_term=sem_nonbranded',
      pants:'Supreme Warm Up Pants', 
      pantsprice: '245$',
      pantsurl: 'https://www.stadiumgoods.com/supreme-warm-up-pants-ss-18-su5117',
      outfitinfo: 'Can be worn in casual days.', 
      outfittype: 'Streetwear look with comfort and casual.',
      shoes: 'Jordan 1 Pine Green',
      shoesprice: '$189+',
      shoesurl: 'https://www.flightclub.com/air-jordan-1-retro-high-og-black-white-gym-red-pine-green-135278',
    },
    {
      id: 0,
      name: "Pink and White",
      icon: 'fas fa-mobile-alt',
      torso: 'Supreme Zig Zag Stitch Panel Crewneck',
      torsoprice: '$220',
      torsourl: 'https://www.stadiumgoods.com/supreme-zig-zag-stitch-panel-crewneck-fw-17-su4402',
      pants:'Printed Joggers', 
      pantsprice: '29.99$',
      pantsurl: 'https://www2.hm.com/en_us/productpage.0917812004.html',
      outfitinfo: 'Worn in parties, outgoing occasions with friends.', 
      outfittype: 'Streetwear look',
      shoes: 'Air Force One 07 White',
      shoesprice: '$20+',
      shoesurl: 'https://www.nike.com/t/air-force-1-07-mens-shoe-5QFp5Z/CW2288-111?nikemt=true',
    },
    {
      id: 0,
      name: "Purp",
      icon: 'fas fa-mobile-alt',
      torso: 'Supreme Stop Crying Hoodie',
      torsoprice: '$390',
      torsourl: 'https://www.stadiumgoods.com/supreme-stop-crying-hoodie-fw-19-su8394',
      pants:'Stadium good Joggers', 
      pantsprice: '85$',
      pantsurl: 'https://www.stadiumgoods.com/stadium-goods-track-pants-sgs0136',
      outfitinfo: 'Purple and Flex. Worn in parties, fests, and casual outside of work days.', 
      outfittype: 'Streetwear look',
      shoes: 'Air Jordan 1 Retro High OG Court Purple 2.0',
      shoesprice: '$221+',
      shoesurl: 'https://www.goat.com/sneakers/air-jordan-1-high-og-court-purple-555088-500?utm_source=google_int&utm_medium=google_shopping_int&utm_campaign=11551954535_111796929839&utm_content=477369526964_&utm_term=193658125574&gclid=Cj0KCQiA962BBhCzARIsAIpWEL0Vh-ug35BMj1DNCMTzw0o72lc4ZSpO5PDtpZ0O-Fh0klyoDTQxmx0aAm5fEALw_wcB',
    },
    {
      id: 0,
      name: "Anti Social Orange",
      icon: 'fas fa-mobile-alt',
      torso: 'Anti Social Social Club Orange Hoodie',
      torsoprice: '$130',
      torsourl: 'https://www.stadiumgoods.com/anti-social-social-club-cpfm-orange-hoodie-as0027',
      pants:'Stadium good Joggers',
      pantsprice: '85$', 
      pantsurl: 'https://www.stadiumgoods.com/stadium-goods-track-pants-sgs0136',
      outfitinfo: 'Loud combo of orange and black.', 
      outfittype: 'Streetwear look',
      shoes: 'Air Max Orange 90',
      shoesprice: '$96+',
      shoesurl: 'https://www.flightclub.com/air-max-90-total-orange-cw5458-101',
    },
  ],
  "culture": [
    {
      id: 0,
      name: "Color Block",
      torso: 'colorblocksweater',
      torsoprice: '$125',
      torsourl: 'https://www.lacoste.com/fr/lacoste/homme/vetements/sweatshirts/sweatshirt-zippe-a-capuche-tennis-lacoste-sport-en-molleton-uni/3614036048133.html',
      pants:'blue jeans',
      pantsprice: '20$',
      pantsurl: 'https://global.lacoste.com/fr/lacoste/homme/vetements/pantalons-shorts/chinos/pantalon-de-survetement-tennis-lacoste-sport-coupe-regular/XH9507-00.html?color=EUA',
      outfitinfo: 'Color block made a huge comeback. Teddy Fresh was and is a huge contributor in bringing this style back on the trend list.', 
      outfittype: 'Streetwear, with a loud sleek look.'
    },
    {
      id: 0,
      name: "Flannel",
      torso: 'Cotton Flannel Shirt',
      torsoprice: '$125',
      torsourl: 'https://www2.hm.com/en_us/productpage.0908891001.html',
      pants:'H and M Ripped Blue jeans',
      pantsprice: '20$',
      pantsurl: 'https://www2.hm.com/en_us/productpage.0427159006.html',
      outfitinfo: 'Flannel and ripped jeans. Two comebacks that are on style.', 
      outfittype: 'Punk, streetwear look'
    },
  ],
};
  
  export { streetwearlist };
  