const vintagelist = {
    "80s": [
      {
        id: 0,
        name: "Streetwear Sleek",
        torso: 'SWEATSHIRT ZIPPÉ À CAPUCHE TENNIS',
        torsoprice: '$70',
        torsourl: 'https://www.lacoste.com/fr/lacoste/homme/vetements/sweatshirts/sweatshirt-zippe-a-capuche-tennis-lacoste-sport-en-molleton-uni/3614036048133.html',
        pants:'PANTALON DE SURVETEMENT',
        pantsprice: '$40',
        pantsurl: 'https://global.lacoste.com/fr/lacoste/homme/vetements/pantalons-shorts/chinos/pantalon-de-survetement-tennis-lacoste-sport-coupe-regular/XH9507-00.html?color=EUA',
        outfitinfo: 'A slick look for a vibe.', 
        outfittype: 'This is a cozy streetwear look, can be used to any occasion',
      },
      {
        id: 0,
        name: "Casual Jean Vibe",
        icon: 'fas fa-mobile-alt',
        torso: 'Authentic jean jacket',
        torsoprice: '$79',
        torsourl: 'https://www.simons.com/en/men-clothing/coats-outerwear/denim-jackets/authentic-jean-jacket--8627-1672335',
        pants:'blue slim jeans', 
        pantsprice: '$13.99',
        pantsurl: 'https://www2.hm.com/en_us/productpage.0751994003.html',
        outfitinfo: 'Jean Jacket, smooth look. Jean Jackets has increased back in popularity today.', 
        outfittype: 'Casual and streetwear',
        shoes: 'Jordan 1 Royal',
        shoesprice: '$450+',
        shoesurl: 'https://www.flightclub.com/air-jordan-1-retro-high-og-black-royal-white-800564?utm_source=google_int&utm_medium=google_shopping_int&utm_campaign=1072630453_50626141377&utm_content=253990353042_&utm_term=887223097921&gclid=CjwKCAiAo5qABhBdEiwAOtGmbgHNR9XAXeqPfa7MKhcMAZ0ywD1RQyMgh4cIIygudr5P2soDrkw4cBoCzB0QAvD_BwE'
    },
    {
      id: 0,
      name: "Band Chill Look",
      icon: 'fas fa-mobile-alt',
      torso: 'Led Zeplin Tee',
      torsoprice: '$25',
      torsourl: 'https://www.pacsun.com/led-zeppelin-t-shirt-9988346.html?&country=US&currency=USD&OriginId=GOG&XCIDP=P:G_PacSun_Shopping_New_Graphic_Tees&gclid=CjwKCAiA9vOABhBfEiwATCi7GFj_h0IPi-n7ZxABdFFcE2uqr6TemiMh8ct3BfVKMVIzrzd0kxbhYxoCdJ8QAvD_BwE',
      pants:'RSQ Denim Mens Pull On Pants', 
      pantsprice: '$49.99',
      pantsurl: 'https://www.tillys.com/product/rsq-denim-mens-pull-on-pants/373773590.html?dwvar_373773590_color=590&cgid=',
      outfitinfo: 'Rock Shirt, Jeans and call it a day. Band shirts are a style being used till this day', 
      outfittype: 'Streetwear look. Jogger jeans is comfort.',
      shoes: 'Jordan 1 Royal',
      shoesprice: '$450+',
      shoesurl: 'https://www.flightclub.com/air-jordan-1-retro-high-og-black-royal-white-800564?utm_source=google_int&utm_medium=google_shopping_int&utm_campaign=1072630453_50626141377&utm_content=253990353042_&utm_term=887223097921&gclid=CjwKCAiAo5qABhBdEiwAOtGmbgHNR9XAXeqPfa7MKhcMAZ0ywD1RQyMgh4cIIygudr5P2soDrkw4cBoCzB0QAvD_BwE'
  },
    ],
    "90s": [
      {
        id: 0,
        name: "Color Block",
        torso: 'colorblocksweater',
        torsoprice: '$85',
        torsourl: 'https://teddyfresh.com/products/color-block-hoodie?variant=32590753202223',
        pants:'blue jeans',
        pantsprice: '$40',
        pantsurl: 'https://global.lacoste.com/fr/lacoste/homme/vetements/pantalons-shorts/chinos/pantalon-de-survetement-tennis-lacoste-sport-coupe-regular/XH9507-00.html?color=EUA',
        outfitinfo: 'Color block made a huge comeback. Teddy Fresh was and is a huge contributor in bringing this style back on the trend list.', 
        outfittype: 'Streetwear, with a loud sleek look.'
      },
      {
        id: 0,
        name: "Flannel",
        torso: 'Cotton Flannel Shirt',
        torsoprice: '$24.99',
        torsourl: 'https://www2.hm.com/en_us/productpage.0908891001.html',
        pants:'H and M Ripped Blue jeans',
        pantsprice: '$39.99',
        pantsurl: 'https://www2.hm.com/en_us/productpage.0427159006.html',
        outfitinfo: 'Flannel and ripped jeans. Two comebacks that are on style.', 
        outfittype: 'Punk, streetwear look'
      },
      {
        id: 0,
        name: "Color Sharp",
        torso: 'Purple Crewneck Sweater',
        torsoprice: '$85',
        torsourl: 'https://standardissuetees.com/products/standard-crewneck-sweater-purple?variant=33315401007203&currency=USD&utm_medium=product_sync&utm_source=google&utm_content=sag_organic&utm_campaign=sag_organic&gclid=CjwKCAiA9vOABhBfEiwATCi7GL8w-aH30BvlIa3MLtt3Qn5Hqgjj31K89O6KlaQxG0kuW1Ogdi0GEBoCLUwQAvD_BwE',
        pants:'Rigid Jeans Stone Wash',
        pantsprice: '$29',
        pantsurl: 'https://www.asos.com/us/asos-design/asos-design-plus-classic-rigid-jeans-in-light-stone-wash-blue/prd/13446036?affid=26122&_cclid=Google_CjwKCAiA9vOABhBfEiwATCi7GEvHZqh4MCRFPE41dDvKa3fzUtCDmVlqqvsXlpc2lWeCjK3eGdHuExoCaIwQAvD_BwE&channelref=product+search&mk=abc&ppcadref=10876634847%7c107095074476%7cpla-296753044553&cpn=10876634847&gclid=CjwKCAiA9vOABhBfEiwATCi7GEvHZqh4MCRFPE41dDvKa3fzUtCDmVlqqvsXlpc2lWeCjK3eGdHuExoCaIwQAvD_BwE&gclsrc=aw.ds',
        outfitinfo: 'Purple made a combeback, meshing with a loose comfy jean', 
        outfittype: 'Streetwear, skater look',
        shoes: 'air force one star pack',
        shoesprice: '$230+',
        shoesurl: 'https://www.flightclub.com/air-force-1-07-qs-sail-sail-sail-803394'
      },
    ],
    "20s": [
      {
        id: 0,
        name: "Chill Look",
        icon: 'fas fa-mobile-alt',
        torso: 'Authentic jean jacket',
        torsourl: 'https://www.simons.com/en/men-clothing/coats-outerwear/denim-jackets/authentic-jean-jacket--8627-1672335',
        pants:'Regular Tapered Cropped Jeans H&M',
        pantsurl: 'https://www2.hm.com/en_us/productpage.0927964003.html',
        outfitinfo: 'Jean Jackets made a huge comemack from the 90s', 
        outfittype: 'Streetwear, and casual look',
        shoes: 'air force one star pack',
        shoesprice: '$230+',
        shoesurl: 'https://www.flightclub.com/air-force-1-07-qs-sail-sail-sail-803394'
    },
    {
      id: 0,
      name: "Sharp Fall Look",
      icon: 'fas fa-mobile-alt',
      torso: 'Bomber Jacket',
      torsoprice: "$160",
      torsourl: 'https://www.alphaindustries.com/collections/mens-shop-all/products/mjm21000c1-ma-1-bomber-jacket-heritage-m?variant=32275790561335',
      pants:'Black Cargo Pants',
      pantsprice: '$23.80',
      pantsurl: 'https://www.aeropostale.com/twill-cargo-joggers/0092466893.html?redirect=false&utm_source=googlepla&utm_medium=cpc&utm_campaign=pla%7Cnb%7Cdesktop%7Cguys%7Cjoggers-sweats&gclid=CjwKCAiA9vOABhBfEiwATCi7GCh-ZIrtNKuJJXmSiHIDhCI9kuQgriTYJFoaB7czn0FcG7_hXzsfsxoChO0QAvD_BwE',
      outfitinfo: 'Bomber Jackets has been on style for quite some recent years.', 
      outfittype: 'Streetwear, and mid casual',
      shoes: 'air force one star pack',
      shoesprice: '$230+',
      shoesurl: 'https://www.flightclub.com/air-force-1-07-qs-sail-sail-sail-803394'
  },
    ],
    "10s": [
    {
      id: 0,
      name: "Minimalist",
      icon: 'fas fa-mobile-alt',
      torso: 'Regular Fit V-neck T-shirt',
      torsoprice: "$5.99",
      torsourl: 'https://www2.hm.com/en_us/productpage.0763275001.html',
      pants:'Minimalist sweatpant',
      pantsprice: '14.99$',
      pantsurl: 'https://www.simons.com/en/men-clothing/pants/joggers/minimalist-sweatpant--1533-9101?catId=6755&colourId=11',
      outfitinfo: 'minimal look, fancy and goes well in any approach', 
      outfittype: 'This is a cozy streetwear look, can be used to any occasion',
      shoes: 'air force one star pack',
      shoesprice: '$230+',
      shoesurl: 'https://www.flightclub.com/air-force-1-07-qs-sail-sail-sail-803394'
    },
    {
      id: 0,
      name: "Casual Desert",
      icon: 'fas fa-mobile-alt',
      torso: 'Regular Fit Crew-neck T-shirt Beige',
      torsoprice: "$6.99",
      torsourl: 'https://www2.hm.com/en_us/productpage.0685816011.html',
      pants:'Skinny Jeans Black H & M',
      pantsprice: '$24.99',
      pantsurl: 'https://www2.hm.com/en_us/productpage.0720504001.html',
      outfitinfo: 'Casual simple look. No text, plain tee. Making it simple, making you mysterous.', 
      outfittype: 'Casual look',
      shoes: 'air force one star pack',
      shoesprice: '$230+',
      shoesurl: 'https://www.flightclub.com/air-force-1-07-qs-sail-sail-sail-803394'
  },
  {
      id: 0,
      name: "Casual Light",
      icon: 'fas fa-mobile-alt',
      torso: 'essentials hoodie black',
      torsoprice: "$160",
      torsourl: 'https://www.grailed.com/listings/20287081-fog-x-fear-of-god-essentials-fear-of-god-hoodie-black-l?',
      pants:'acid wash fleece sweatpant',
      pantsprice: '225$',
      pantsurl: 'https://www.alexanderwang.com/us-en/ACID-WASH-DENSE-FLEECE-SWEATPANT+6C194028X7007.html',
      shoes: 'air force one star pack',
      shoesprice: '$230+',
      shoesurl: 'https://www.flightclub.com/air-force-1-07-qs-sail-sail-sail-803394',
      outfitinfo: 'Light cozy outfit', 
      outfittype: 'Light type outfit',
  },
  {
      id: 0,
      name: "Skater Casual",
      icon: 'fas fa-mobile-alt',
      torso: 'Cotton Flannel Shirt',
      torsoprice: "$24.99",
      torsourl: 'https://www2.hm.com/en_us/productpage.0908891001.html',
      pants:'Skinny Jeans Dark Shade H & M',
      pantsprice: '24.99$',
      pantsurl: 'https://www2.hm.com/en_us/productpage.0720504008.html',
      outfitinfo: 'This timeless outfit can be used in dating giving a streetwear vibe.', 
      outfittype: 'This is a casual streetwear look.',
      shoes: 'air force one star pack',
      shoesprice: '$230+',
      shoesurl: 'https://www.flightclub.com/air-force-1-07-qs-sail-sail-sail-803394'
  },
  ],
  };
  
  export { vintagelist };
  